import React from "react";

export default function Navbar({ fixed }: any) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  // return (
  //   //0.25 0.4
  //   <>
  //     <nav className="flex-wrap items-center justify-between px-2 py-2 bg-blue-400 mb-3 sticky top-0 z-[2] text-center">
  //       <p className="tracking-widest font-semibold text-white">AIRTAGS TRACKER</p>
  //       {/* <div className="container px-4 mx-auto flex flex-wrap items-center justify-between text-center"></div> */}
  //     </nav>
  //   </>
  // );
  return (
    //0.25 0.4
    <>
      <nav className="flex-wrap items-center justify-between px-2 py-2 bg-blue-400 mb-3 sticky top-0 z-[2]">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex flex-col">
              <h2 className="text-xl font-bold inline-block pr-8 text-white">
                <i
                  style={{
                    display: "inline-block",
                    boxShadow: "0 0 0px white",
                    //   padding: "0.27em 0.4em",
                    // transform: "rotate(-90deg)",
                  }}
                  className="fas fa-plane-departure mr-1"
                ></i>
                AirTags
              </h2>
              <h4
                className="text-l font-regular inline-block text-right text-white"
                style={{ marginTop: "-6px" }}
              >
                App
                <i
                  style={{
                    display: "inline-block",
                    boxShadow: "0 0 0px white",
                    padding: "0.27em 0.4em",
                    border: "solid white 2px",
                  }}
                  className="fab fa-apple rounded-full ml-1"
                ></i>
              </h4>
            </div>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex  border-t-2 mt-2 " : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                  <span className="">Airtags</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                  <span className="">visited countries</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                  <span className="m">all airtagges</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
