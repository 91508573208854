import React from "react";

interface Props {}

const airtag = {
  _id: "62372bd089214a29c5f8c1ba",
  title: "Package",
  serial: "HGHH2F0DP0GV",
  latestLocationUpdate: {
    start: "2022-03-20T11:53:23.000Z",
    end: "2022-03-20T13:23:55.000Z",
    pings: 4,
    latitude: 31.806059311699926,
    longitude: 34.64566802355915,
    accuracy: 35.25211643259422,
    battery: 100,
    address: {
      streetAddress: "31",
      streetName: "Harishonim Street",
      stateCode: null,
      stateName: "Southern District",
      locality: "Ashdod",
      countryCode: "IL",
      country: "Israel",
      zip: null,
      areaOfInterest: [],
    },
  },
  currentJourney: null,
};

const AirtagOverview: React.FC = () => {
  return (
    <div className="min-w-[290px] rounded overflow-hidden text-left shadow-lg bg-white aline-centers mb-10 ">
      <div className="flex justify-between bg-sky-600 p-2">
        <div className="">
          <h2 className="text-xl leading-6 text-white  font-extrabold">Alpha</h2>
          <p className="text-lg text-zinc-300 font-normal	">Traveling to Israel </p>
        </div>

        <div
          className="icon my-auto rounded-full flex"
          style={{
            width: "48px",
            height: "48px",
            boxShadow: "#00000091 0px 0px 7px",
            border: "solid white 2px",
            background: "#ff9e7a",
          }}
        >
          {/* <i
            style={{
              flexGrow: 0,
              display: "inline-block",
              padding: "0.399em 0.37em",
            }}
            className="fab fa-apple rounded-full text-[25px] leading-none text-white ml-1"
          ></i> */}
          <div className="m-auto">
            <img
              src="https://flagcdn.com/40x30/za.png"
              srcSet="https://flagcdn.com/28x21/za.png 2x,
    https://flagcdn.com/120x90/za.png 3x"
              width="27"
              height="17"
              alt="South Africa"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between  mx-2 text-sm my-[-8px] text-white">
        <div className="lastseen bg-sky-600 px-1 rounded-md">
          <i className="fas fa-clock" /> 43 min
        </div>
        <div className="bat bg-sky-600 px-1 rounded-md">
          <i className="fas fa-battery-half" />
        </div>
      </div>
      <div className="my-4"></div>
      {/* <i
        style={{
          display: "inline-block",
          boxShadow: "#00000091 0px 0px 7px",
          padding: "0.5em 0.6em",
          border: "solid white 2px",
          background: "#ff9e7a",
        }}
        className="fab fa-apple rounded-full text-xl leading-none text-white ml-1"
      /> */}
      <div>
        <h3 className="text-xl">Test Airtag</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
        <h3 className="text-l">Used to test the system</h3>
      </div>
    </div>
  );
};
export default AirtagOverview;
